import React, { useEffect, useState, useRef } from "react";
import CommonNavButtons from "../../utils/components/commonNavButtons/CommonNavButtons";
import Sidebar from "../../utils/components/sideBar/sideBar";
import NavigationBar from "../../utils/components/navbar/Navbar";
import Carousel from "../../utils/components/Carousel/carousel";
import Footer from "../../utils/components/footer/Footer";
import MyCard from "../../utils/components/cards/cards";

import Form1 from "../../utils/components/form/Form1";

const SIDEBAR_WIDTH = 300;

const Home = () => {
  //SideBar
  const [navbarVisible, setNavbarVisible] = useState(true);
  const [containerWidth, setContainerWidth] = useState("100%");
  const containerRef = useRef(null);
  const handleSidebarVisibility = () => {
    const width = navbarVisible
      ? "100%"
      : containerRef.current.offsetWidth - SIDEBAR_WIDTH;
    setContainerWidth(width);
    setNavbarVisible(!navbarVisible);
  };

  return (
    <>
      <div className="container-fluid">
        <div ref={containerRef}>
          <NavigationBar onToggleSideBar={handleSidebarVisibility} />
          <div className="row">
            <div className="col-md-3">
              <Sidebar
                visible={navbarVisible}
                onClick={handleSidebarVisibility}
              />
            </div>
            <div className="col-md-8">
              <Carousel />
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="row">
            <div className="col-6 col-md-4"></div>
            <div className="col-6 col-md-4">
              <MyCard />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
