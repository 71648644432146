import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faSignOutAlt,
  faHome,
  faFile,
  faAddressBook,
  faAmazon,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faGoogle,
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="fixed-bottom">
      <footer
        className="text-center text-black text-muted"
        style={{ backgroundColor: "#f1f1f1" }}
      >
        <div className="float-end mb-1">
          {/* Grid container */}
          <div className="container pt-4">
            {/* Section: Social media */}
            <section className="mb-4">
              {/* Facebook */}

              {/* Twitter */}
              <a
                className="btn btn-link btn-floating btn-lg text-dark m-1"
                href="#!"
                role="button"
              >
                <FontAwesomeIcon icon={faGoogle} />
              </a>
              {/* Google */}
              <a
                className="btn btn-link btn-floating btn-lg text-dark m-1"
                href="#!"
                role="button"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              {/* Instagram */}
              <a
                className="btn btn-link btn-floating btn-lg text-dark m-1"
                href="#!"
                role="button"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              {/* Linkedin */}
              <a
                className="btn btn-link btn-floating btn-lg text-dark m-1"
                href="#!"
                role="button"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
              {/* Github */}
              <a
                className="btn btn-link btn-floating btn-lg text-dark m-1"
                href="#!"
                role="button"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </section>
            {/* Section: Social media */}
          </div>
          {/* Grid container */}
          {/* Copyright */}
          <div
            className="text-center text-dark p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
          >
            © Footer
            <a className="text-dark"></a>
          </div>
          {/* Copyright */}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
