import "./style.css";
import React, { useContext } from "react";
import { Nav } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/logo.png";

const NavigationBar = ({}) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="w-100">
          <nav className="navbar justify-content-center">
            <a className="navbar-brand" href="#">
              <img src={Logo} className="img-fluid" width="200" />
            </a>

            <form className="form-inline col-md-9 justify-content-end d-flex">
              <div className="searchBar input-group col-md-9 ">
                <input
                  className="form-control mr-sm-2 noBorders"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button
                  className="btn btn-outline-primary my-2 my-sm-0 noBorders"
                  type="submit"
                >
                  <FontAwesomeIcon icon={faSearch} style={{ fontSize: 20 }} />
                </button>
              </div>
              <Nav>
                <Nav.Link className="nav-link">
                  <button className="btn  my-2 my-sm-0 noBorders" type="submit">
                    Login
                  </button>
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link className="nav-link">
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    style={{ fontSize: 20 }}
                  />
                </Nav.Link>
              </Nav>
            </form>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
