import React from "react";
import { useHistory } from "react-router-dom";
import { Nav } from "react-bootstrap";
const MyCard = ({ course, enrollmentId }) => {
  const history = new useHistory();

  return (
    <div className="btn col-md-10" style={{ marginTop: "25px" }}>
      <div>
        <div style={cardTint}>
          <div style={pushToBottom}>
            <div
              className="d-flex justify-content-start"
              style={infoCardBackground}
            >
              <div className="row d-flex justify-content-start">
                <div
                  className="col d-flex justify-content-start"
                  style={titleStyle}
                >
                  Card 1
                </div>
              </div>
              <div className="row d-flex justify-content-start">
                <div
                  className="col d-flex justify-content-start"
                  style={subTitleStyle}
                >
                  Short Description here
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const cardTint = {
  width: "100%",
  height: "20em",
  borderRadius: "5px",
  padding: "10px",
  background: `linear-gradient(146deg, rgba(6,78,155,0.8) 0%, rgba(255,255,255,0.5) 100%)`,
};

const pushToBottom = {
  width: "100%",
  bottom: "0",
  left: "0",
  position: "absolute",
  padding: "10px",
};

const infoCardBackground = {
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "5px",
  padding: "10px",
  height: "5em",
  background:
    "linear-gradient(146deg, rgba(255,255,255,0.8) 2%, rgba(234,240,240,0.3) 100%)",
};

const titleStyle = {
  fontSize: "1.2em",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const subTitleStyle = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
export default MyCard;
