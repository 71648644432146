import React, { useState } from "react";
import "./sideBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUser, faCog } from "@fortawesome/free-solid-svg-icons";

import { Accordion, Card } from "react-bootstrap";

const SIDEBAR_WIDTH = 300;
const Sidebar = ({ visible }) => {
  return (
    <div
      id="mySidebar"
      className="sidebar"
      style={{
        width: visible ? SIDEBAR_WIDTH : 0,
      }}
    >
      <div id="main">
        <button className="openbtn">
          <span className="mr-2">
            <FontAwesomeIcon icon={faUser} style={{ fontSize: 20 }} />
          </span>
          Profile
        </button>
      </div>
      <div className="col-9 btn d-flex justify-content-start"></div>
      <Accordion>
        <Card style={{ borderRadius: "5px" }}>
          <Accordion.Toggle eventKey="0" style={toggleTitle}>
            <div className="row">
              <div
                className="col-2 d-flex justify-content-center"
                style={{ alignItems: "center" }}
              ></div>
              <div className="col-8">
                <FontAwesomeIcon icon={faHome} style={{ fontSize: 20 }} />
                <span> Home</span>
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div className="row">
                <div className="col-2 justify-content-center"></div>
                <div className="col-8 btn">Orders</div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card style={{ borderRadius: "5px" }}>
          <Accordion.Toggle eventKey="1" style={toggleTitle}>
            <div className="row">
              <div
                className="col-2 d-flex justify-content-center"
                style={{ alignItems: "center" }}
              ></div>
              <div className="col-8">
                <FontAwesomeIcon icon={faCog} style={{ fontSize: 20 }} />
                <span> Settings</span>
              </div>
            </div>
          </Accordion.Toggle>
        </Card>
      </Accordion>
    </div>
  );
};

const toggleTitle = {
  border: "none",
  height: "4em",
  justifyContent: "start",
  textAlign: "start",
  background:
    "linear-gradient(90deg, rgba(255,255,255,1) 2%, rgba(234,240,240,1) 100%)",
  overflow: "hidden",
  // whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export default Sidebar;
