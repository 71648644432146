import React, { useState } from "react";
import education from "./education.jpg";
const CarouselSlider = () => {
  return (
    <div className="conatiner-fluid">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to={0}
            className="active"
          />
          <li data-target="#carouselExampleIndicators" data-slide-to={1} />
          <li data-target="#carouselExampleIndicators" data-slide-to={2} />
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="d-block w-100" src={education} alt="First slide" />
            <div class="carousel-caption d-none d-md-block">
              <h5 class="display-4">Slider 1</h5>
              <p class="mb-0"> Please write the short discrption here</p>
            </div>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={education} alt="Second slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={education} alt="Third slide" />
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
};

export default CarouselSlider;
