import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Col } from "react-bootstrap";

function myForm() {
  return (
    <div>
      {/* This div contains simple form with email, password, checkbox and submitt button */}
      <div style={{ marginTop: "50px" }}>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" />
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
          </Form.Group>
          <Button variant="dark" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    
      
    </div>
  );
}

export default myForm;