import "./App.css";

import Home from "./pages/home/Home";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";


function App() {
  return (
    <>
        <Router>
          <Route exact path="/" component={Home} />
        </Router>
     
    </>
  );
}

export default App;
